import * as React from 'react'
import { Helmet } from 'react-helmet'

import Link from 'gatsby-link'

import Loadable from '@loadable/component'

const SignIn = ({ firebase }) => {
  const LoadableFirebaseAuth = Loadable(() =>
    import('../components/firebase-auth')
  )

  return (
    <>
      <Helmet
        title="Sign in to your Phone Company"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      />
      <h1>Sign in</h1>
      <LoadableFirebaseAuth firebase={firebase} />
    </>
  )
}

export default SignIn
