import * as React from 'react'

import Loadable from '@loadable/component'

const FirebaseLoadable = props => {
  const LoadableFirebaseBase = Loadable(() =>
    import('../components/firebase-base')
  )

  return <LoadableFirebaseBase {...props} />
}

export default FirebaseLoadable
