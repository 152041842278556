import * as React from 'react'

import { Router, RouteComponentProps } from '@reach/router'

import DefaultLayout from '../layouts/index'

import FirebaseLoadable from '../components/firebase-loadable'
import PrivateRoute from '../components/private-route'
import SignIn from '../components/sign-in'
import BridgeCall from '../components/bridge-call'

const Routable = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

const Yo = ({ user }) => {
  if (user) {
    return <div> Welcome, {user.displayName}! </div>
  } else {
    return <div> Yo </div>
  }
}

// TODO(Alex): Figure out how to dynamically set the pathname for the default
// layout
const App = () => {
  let resolver
  let rejecter
  const firebasePromise = new Promise((resolve, reject) => {
    resolver = resolve
    rejecter = reject
  })
  const [firebase, setFirebase] = React.useState(undefined)
  firebasePromise.then(fb => {
    setFirebase(fb)
  })

  return (
    <DefaultLayout location={{ pathname: 'app' }}>
      <FirebaseLoadable resolver={resolver} />
      {!firebase ? (
        <div>Initializing Firebase...</div>
      ) : (
        <Router basepath="/app">
          <PrivateRoute path="/" firebase={firebase} component={BridgeCall} />
          <Routable path="/sign-in" component={SignIn} firebase={firebase} />
        </Router>
      )}
    </DefaultLayout>
  )
}

export default App
