import * as React from 'react'
import { Helmet } from 'react-helmet'

import Link from 'gatsby-link'

import Loadable from '@loadable/component'
import { Button, TextField } from '@material-ui/core'

const initiateBridge = (firebase, agentNumber, targetNumber) => {
  firebase
    .auth()
    .currentUser.getIdToken()
    .then(async token => {
      const response = await fetch('https://phonecompany.io/f/bridge', {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          agentNumber: agentNumber,
          accountIntermediaryNumber: '+12342556669',
          targetNumber: targetNumber,
        }),
      })
    })
}

const BridgeCall = ({
  firebase,
  fromNumber = '+19192590457',
  toNumber = '+19194428765',
}) => {
  const [agentNumber, setAgentNumber] = React.useState(fromNumber)
  const [targetNumber, setTargetNumber] = React.useState(toNumber)

  return (
    <>
      <Helmet
        title="Make a call"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      />
      <h1>Bridge a call from your number to theirs</h1>
      <form>
        <TextField
          id="agentNumber"
          label="Your phone number"
          onChange={event => setAgentNumber(event.target.value)}
          value={agentNumber}
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <br />

        <TextField
          id="targetNumber"
          label="Their phone number"
          onChange={event => setTargetNumber(event.target.value)}
          value={targetNumber}
          margin="normal"
          fullWidth
          variant="outlined"
        />
        <br />

        <Button variant="contained" color="primary"
          onClick={() => initiateBridge(firebase, agentNumber, targetNumber)}>
          Initiate call
        </Button>
      </form>
    </>
  )
}

export default BridgeCall
