import * as React from 'react'

import { navigate } from 'gatsby'
import { Router, RouteComponentProps } from '@reach/router'

export const isBrowser = () => typeof window !== 'undefined'

const PrivateRoute = ({ firebase, component: Component, ...rest }) => {
  const [user, setUser] = React.useState(undefined)

  if (isBrowser()) {
    React.useEffect(() => {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in
          setUser(user)
        } else {
          // User is not signed in
          setUser(undefined)
          navigate(`/app/sign-in`)
        }
      })
    })
  }

  return (
    <Router>
      <Component user={user} firebase={firebase} {...rest} />
    </Router>
  )
}

export default PrivateRoute
